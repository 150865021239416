import React from 'react';
import './MenuHamburger.scss';
import './MenuHamburger-media.scss';
import { Props } from './interfaces';

const MenuHamburger = ({ onClick }: Props) => {
  return (
    <div className="menu-icon" onChange={onClick}>
      <input className="menu-icon__cheeckbox" type="checkbox"/>
      <div>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default MenuHamburger;

import React, { useEffect, useState, useCallback } from 'react';
import { Swiper, SwiperSlide, Autoplay, Pagination, EffectCreative, TypeSwiper } from '../../services/ImportsSwiper';
import { Props } from './interfaces';
import './SliderGallery.scss';
import './SliderGallery-media.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import 'swiper/css/effect-creative';

const SliderGallery = ({ title, slides, countDotsSlides, subtitle }: Props) => {
  const [numActiveSlide, setNumActiveSlide] = useState(0);
  const [slider, setSlider] = useState<TypeSwiper>();
  const [sliderPagination, setSliderPagination] = useState<TypeSwiper>();

  const handleSlideChange = useCallback((current: TypeSwiper) => {
    setNumActiveSlide(current.activeIndex);
  }, []);

  useEffect(() => {
    if (slider && sliderPagination) {
      slider.slideTo(numActiveSlide, 500);
      sliderPagination.slideTo(numActiveSlide, 500);
    }
  }, [numActiveSlide, slider, sliderPagination]);

  const settingsAll = {
    autoplay: false,
    speed: 500, // Скорость анимации перехода между слайдами (в миллисекундах)
    modules: [Autoplay, Pagination, EffectCreative],
    onSlideChange: handleSlideChange,
  };

  const settings = {
    ...settingsAll,
    spaceBetween: 20,
    className: 'slider-gallery',
    slidesPerView: 1,
  };

  const settingsPaginationSlider = {
    ...settingsAll,
    spaceBetween: 8,
    className: 'slider-gallery-dots',
    centeredSlides: true,
    slidesPerView: countDotsSlides || 5,
    slideToClickedSlide: true,
  };

  const prevSlide = useCallback(() => {
    if (slider && sliderPagination) {
      slider.slidePrev(500, false);
      sliderPagination.slidePrev(500, false);
    }
  }, [slider, sliderPagination]);

  const nextSlide = useCallback(() => {
    if (slider && sliderPagination) {
      slider.slideNext(500, false);
      sliderPagination.slideNext(500, false);
    }
  }, [slider, sliderPagination]);

  if (!slides.length) return null;

  return (
    <>
      {(title || subtitle) && (
        <div className="slider-gallery__header">
          {title && <h2 className="slider-gallery__title">{title}</h2>}
          {subtitle && <p className="slider-gallery__subtitle">{subtitle}</p>}
        </div>
      )}

      <div className="slider-gallery">
        <Swiper
          {...settings}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ['-20%', 0, -1],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          onInit={setSlider}
        >
          {slides.map((item, i) => (
            <SwiperSlide key={i}>
              <img
                src={`${baseURLModx}/${item.image}`}
                className="slider-gallery__image"
                alt={item.alt || ''}
                loading="lazy"
              />
            </SwiperSlide>
          ))}

          <div className="slider-gallery__arrows">
            <button
              className="slider-gallery__arrow slider-gallery__arrow_prev"
              onClick={prevSlide}
              aria-label="Previous slide"
            />
            <button
              className="slider-gallery__arrow slider-gallery__arrow_next"
              onClick={nextSlide}
              aria-label="Next slide"
            />
          </div>
        </Swiper>

        <div className="slider-gallery__dots">
          <Swiper
            {...settingsPaginationSlider}
            onInit={setSliderPagination}
          >
            {slides.map((item, i) => (
              <SwiperSlide key={i}>
                <div
                  style={{ backgroundImage: `url(${baseURLModx}/${item.image})` }}
                  className="slider-gallery__dot"
                  aria-label={`Go to slide ${i + 1}`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SliderGallery;
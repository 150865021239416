import React, { useState, createRef, RefObject } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './NewBlockVideo.scss';
import './NewBlockVideo-media.scss';
import { HandlerEndedVideo, HandlerPlayPauseVideo } from './functions';
import { Props } from './interfaces';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import {SwiperSlide} from "../../services/ImportsSwiper";

const NewBlockVideo = ({
  title,
  text,
  video,
  poster,
  height,
  heightMob,
  autoPlay = false,
  controls = false,
  loader = false,
  fullScreen = false,
  fullText,
  hiddenPlay = false,
  marquee,
  content,
  widthWindow,
  buttonsBlock
}: Props) => {
  const [play, setPlay] = useState(false);
  const player: RefObject<HTMLVideoElement> = createRef();
  const [settingsAutoPlay, setSettingsAutoPlay] = useState(autoPlay);

  const onPause = () => setPlay(false);

  const heightVideo = fullScreen ? 'auto' : widthWindow > 720 ? height : heightMob;

  console.log(title);

  return (
    <>
      <div className="newVideoHeader">
        <video
          ref={player}
          width="100%"
          autoPlay={autoPlay}
          loop={autoPlay}
          muted={autoPlay}
          playsInline={autoPlay}
          className="newVideoHeader__video"
          poster={loader ? require('./img/loader.gif') : poster}
          onClick={!autoPlay ? HandlerPlayPauseVideo({play, player, setPlay}) : () => {
          }}
          onEnded={HandlerEndedVideo({player, setPlay, setSettingsAutoPlay, poster})}
          controls={controls}
          onPlay={autoPlay ? HandlerPlayPauseVideo({play, player, setPlay}) : () => {
          }}
          onPause={autoPlay ? onPause : () => {
          }}
        >
          <source src={`${baseURLModx}${video}`} type="video/mp4"/>
        </video>

        <div className="newVideoHeader__content">
          <div className="newVideoHeader__container">
            {title && <h2 className="newVideoHeader__title">{title}</h2>}
            {text && <p className="subtitle1 content-for-media__text">{text}</p>}

            <div className="content-for-media__buttons">{buttonsBlock}</div>
          </div>
          <div className="items-wrap">
            <div className="items marquee">
              {marquee?.map((item, i) => (
                <span className="item" key={i}>{item}</span>
              ))}
            </div>
            <div aria-hidden="true" className="items marquee">
              {marquee?.map((item, i) => (
                <span className="item" key={i}>{item}</span>
              ))}
            </div>
          </div>
        </div>

      </div>

      {/*<div className={`block-video${fullScreen ? ' block-video_full-width' : ''}`}>*/}
      {/*  {title ? <h2 className="block-video__title">{title}</h2> : null}*/}


      {/*</div>*/}

      {/*{fullScreen ? <div className="block-video__full-screen">{content}</div> : null}*/}
    </>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(NewBlockVideo));

import React from 'react';
import './MenuHamburger.scss';
import './MenuHamburger-media.scss';
import { Props } from './interfaces';

const MenuHamburgerStub = ({ onClick }: Props) => {
  return (
    <>
      {/*<div className="menu-hamburger menu-hamburger_stub" onClick={onClick}>*/}
      {/*  <span className="menu-hamburger__line line1"></span>*/}
      {/*  <span className="menu-hamburger__line line2"></span>*/}
      {/*  <span className="menu-hamburger__line line3"></span>*/}
      {/*</div>*/}
      <div className="menu-icon active">
        <input className="menu-icon__cheeckbox" type="checkbox" onChange={onClick}/>
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default MenuHamburgerStub;
